import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import { Department } from "../../../type/department";
import Organizer from "./Organizer";
import VideoLinkPopup from "./VideoLinkPopup";
import Video from "./Video";
import LanguagePoint from "../../Global/LanguagePoint";
import CustomDate from "../../Global/CustomDate";
import Participants from "../../Global/Participants";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import PlantingDateWarning from "../../ErrorAndAlert/PlantingDateWarning";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberField from "../../Global/NumberField";
import { isSupportedFormat } from "../../Global/supportedFormat.";

type FormValues = {
  name: string;
  date: Date;
  psaStudent: number;
  schoolStudent: number;
  psaTutor: number;
  schoolTeacher: number;
  farmers: number;
  promoters: number;
  junior_youth_parents: number;
  community_leaders: number;
  other: number;
  participants: string;
  plantedTrees: number;
  department: Department;
  municipality: Department;
  community: Department;
  type: string;
};

const AddPlantingForm: React.FC<{
  send: (data: any) => void;
  selectOptions: {
    department: Department[];
    grp: Department[];
    ins: Department[];
    ind: Department[];
    fml: Department[];
  };
  sendResponse: any;
  addOrganizer: (type: string, value: Department) => void;
  getInfo?: any;
  textButton: string;
  eventType?: (type: string) => void;
  onTabChange: (tab: string) => void;
}> = ({
  send,
  selectOptions,
  sendResponse,
  addOrganizer,
  getInfo,
  textButton,
  eventType,
  onTabChange,
}) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("EN");
  const [translatableInput, setTranslatableInput] = useState<{
    name: TranslationField;
    description: TranslationField;
    comment: TranslationField;
  }>({
    name: { en: "", fr: "" },
    description: { en: "", fr: "" },
    comment: { en: "", fr: "" },
  });
  const [video, isVideo] = useState<boolean>(false);
  const [organizers, setOrganizers] = useState<
    { type: string; collaborators: Department[] }[]
  >([{ type: "", collaborators: [] }]);
  const [resources, setResources] = useState<file[]>([]);
  const [municipalities, setMunicipalities] = useState<Department[]>([]);
  const [communities, setCommunities] = useState<Department[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, isShowError] = useState<boolean>(false);
  const [uploading, isUploading] = useState<boolean>(false);
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [videos, setVideos] = useState<{ link: string; title: string }[]>([]);
  const [oldVideos, setOldVideos] = useState<
    { id: number; link: string; title: string }[]
  >([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [showDateWarning, isShowDateWarning] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    register,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      psaStudent: 0,
      schoolStudent: 0,
      psaTutor: 0,
      schoolTeacher: 0,
      farmers: 0,
      promoters: 0,
      junior_youth_parents: 0,
      community_leaders: 0,
      other: 0,
    },
  });
  const departmentValue = watch("department");
  const municipalityValue = watch("municipality");
  const typeValue = watch("type");

  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );
  const { response: municipalityRes, fetchData: getMunicipality } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${departmentValue?.slug}/municipalities/all/`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: communityRes, fetchData: getCommunity } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${municipalityValue?.slug}/communities/all/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const history = useHistory();

  useEffect(() => {
    if (typeValue && eventType) {
      eventType(typeValue);
    }
  }, [typeValue, eventType]);

  useEffect(() => {
    if (departmentValue?.slug) {
      getMunicipality({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue]);

  useEffect(() => {
    if (municipalityValue?.slug) {
      getCommunity({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityValue]);

  useEffect(() => {
    if (municipalityRes) {
      setMunicipalities(municipalityRes.data);
    }

    if (communityRes) {
      setCommunities(communityRes.data);
    }
  }, [municipalityRes, communityRes]);

  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    if (sendResponse) {
      setRequestNumber((prevState) => prevState - 1);
      isUploading(true);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "Planting");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setOrganizers([]);
      setTranslatableInput({
        description: getInfo.data.description_transes,
        name: getInfo.data.name_transes,
        comment: getInfo.data.comment_transes,
      });
      let indCollaborator: Department[] = [];
      let insCollaborator: Department[] = [];
      let grpCollaborator: Department[] = [];
      let fmlCollaborator: Department[] = [];
      (
        getInfo.data.organizers as {
          type: string;
          slug: string;
          name: string;
        }[]
      ).forEach((organizer) => {
        switch (organizer.type) {
          case "ind":
            indCollaborator.push({
              name: organizer.name,
              slug: organizer.slug,
            });
            break;
          case "grp":
            grpCollaborator.push({
              name: organizer.name,
              slug: organizer.slug,
            });
            break;
          case "ins":
            insCollaborator.push({
              name: organizer.name,
              slug: organizer.slug,
            });
            break;
          case "fml":
            fmlCollaborator.push({
              name: organizer.name,
              slug: organizer.slug,
            });
            break;
          default:
            break;
        }
      });
      if (indCollaborator.length > 0) {
        setOrganizers((prevState) => [
          ...prevState,
          {
            type: "ind",
            collaborators: indCollaborator,
          },
        ]);
      }
      if (grpCollaborator.length > 0) {
        setOrganizers((prevState) => [
          ...prevState,
          {
            type: "grp",
            collaborators: grpCollaborator,
          },
        ]);
      }
      if (insCollaborator.length > 0) {
        setOrganizers((prevState) => [
          ...prevState,
          {
            type: "ins",
            collaborators: insCollaborator,
          },
        ]);
      }
      if (fmlCollaborator.length > 0) {
        setOrganizers((prevState) => [
          ...prevState,
          {
            type: "fml",
            collaborators: fmlCollaborator,
          },
        ]);
      }

      const initialTypes = [
        ...(indCollaborator.length > 0 ? ["ind"] : []),
        ...(grpCollaborator.length > 0 ? ["psa"] : []),
        ...(insCollaborator.length > 0 ? ["ins"] : []),
        ...(fmlCollaborator.length > 0 ? ["fml"] : []),
      ];
      setSelectedTypes(initialTypes);

      const dateF: string[] = getInfo.data.date.split("/");
      setValue(
        "date",
        new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
      );
      setValue("psaStudent", getInfo.data.psa_students);
      setValue("schoolStudent", getInfo.data.school_students);
      setValue("psaTutor", getInfo.data.psa_tutors);
      setValue("schoolTeacher", getInfo.data.school_tutors);
      setValue("farmers", getInfo.data.farmers);
      setValue("promoters", getInfo.data.promoters);
      setValue("junior_youth_parents", getInfo.data.junior_youth_parents);
      setValue("community_leaders", getInfo.data.community_leaders);
      setValue("other", getInfo.data.other_participants);
      setValue("type", getInfo.data.type.toLowerCase());
      setValue("department", {
        slug: getInfo.data.department_slug,
        name: getInfo.data.department,
      });
      setValue("municipality", {
        slug: getInfo.data.municipality_slug,
        name: getInfo.data.municipality,
      });
      setValue("community", {
        slug: getInfo.data.community_slug,
        name: getInfo.data.community,
      });
      setValue("plantedTrees", getInfo.data.planted_trees);
      setOldResource(getInfo.data.files);
      setOldVideos(getInfo.data.video_files);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "Planting");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);

  useEffect(() => {
    if (requestNumber === 0) {
      reset();
      toast.success(
        `${
          getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      if (typeValue?.toLocaleUpperCase() === "PT" || getInfo) {
        history.replace("/events");
      } else {
        onTabChange("detail");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  const plantedTreesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("plantedTrees");
    }
  };

  const participantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("participants");
    }
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: { en: event.target.value, fr: prevState.description.fr },
        };
      });
    } else if (lang === "FR") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: { en: prevState.description.en, fr: event.target.value },
        };
      });
    }
  };

  const commentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          comment: { en: event.target.value, fr: prevState.comment.fr },
        };
      });
    } else if (lang === "FR") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          comment: { en: prevState.comment.en, fr: event.target.value },
        };
      });
    }
  };

  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else if (!isSupportedFormat(f.name)) {
        toast.error(t("Message.file supported formats"));
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const insertVideo = (link: string, title: string) => {
    setVideos((prevState) => [...prevState, { link, title }]);
  };

  const deleteOldVideo = (videoIndex: number) => {
    setOldVideos((prevState) =>
      prevState.filter((prevItem, index: number) => index !== videoIndex)
    );
  };

  const deleteNewVideo = (videoIndex: number) => {
    setVideos((prevState) =>
      prevState.filter((prevItem, index: number) => index !== videoIndex)
    );
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };

  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };

  const typeChange = (index: number, value: string) => {
    const item = organizers.map((orginizer, i) => {
      if (i === index) {
        return { type: value, collaborators: [] };
      } else {
        return orginizer;
      }
    });
    setOrganizers(item);

    const updatedTypes = item.map((org) => org.type);
    setSelectedTypes(updatedTypes);
  };

  const organizerNameChange = (index: number, value: Department[]) => {
    const item = organizers.map((organizer, i) => {
      if (i === index) {
        return { type: organizer.type, collaborators: value };
      } else {
        return organizer;
      }
    });
    setOrganizers(item);
  };
  const deleteOrganizer = (index: number) => {
    const removedOrganizer = organizers[index];
    const updatedOrganizers = organizers.filter((_, i) => i !== index);
    setOrganizers(updatedOrganizers);

    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.filter((type) => type !== removedOrganizer.type)
    );
  };
  const handleAddOrganizer = () => {
    if (organizers.length < 4) {
      setOrganizers([...organizers, { type: "", collaborators: [] }]);
    }
  };

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { en: event.target.value, fr: prevState.name.fr },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name");
      }
    } else if (lang === "FR") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { fr: event.target.value, en: prevState.name.en },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name");
      }
    }
  };

  const addPlantingItem = (data: FormValues) => {
    if (organizers.some((organizer) => organizer.collaborators.length === 0)) {
      return;
    }
    if (
      (translatableInput.description.fr.trim() !== "" &&
        translatableInput.description.en.trim() === "") ||
      (translatableInput.name.fr.trim() !== "" &&
        translatableInput.name.en.trim() === "")
    ) {
      toast.error(`${t("Message.Empty default language error")}`);
    } else {
      let coll: string[] = [];
      organizers.forEach((or) => {
        or.collaborators.forEach((c) => {
          coll.push(c.slug);
        });
      });
      isDirty(false);
      if (!getInfo) {
        send({
          name_transes: translatableInput.name,
          date: `${data.date.getDate()}/${
            data.date.getMonth() + 1
          }/${data.date.getFullYear()}`,
          community: data.community.slug,
          organizers: coll,
          psa_students: data.psaStudent || 0,
          school_students: data.schoolStudent || 0,
          psa_tutors: data.psaTutor || 0,
          school_tutors: data.schoolTeacher || 0,
          farmers: data.farmers || 0,
          promoters: data.promoters || 0,
          junior_youth_parents: data.junior_youth_parents || 0,
          community_leaders: data.community_leaders || 0,
          other_participants: data.other || 0,
          planted_trees: data.type === "pt" ? data.plantedTrees : null,
          type: data.type.toLocaleUpperCase(),
          description_transes: translatableInput.description,
          video_files: videos,
          comment_transes: translatableInput.comment,
        });
      } else {
        // Splitting the date string into day, month, and year components
        const dateComponents = getInfo.data.date.split("/");
        const day = parseInt(dateComponents[0], 10);
        const month = parseInt(dateComponents[1], 10) - 1; // Adjusting month to start from 0
        const year = parseInt(dateComponents[2], 10);

        // Creating a new Date object with the parsed components
        const getInfoDate = new Date(year, month, day);
        const formattedGetInfoDate = `${getInfoDate.getDate()}/${
          getInfoDate.getMonth() + 1
        }/${getInfoDate.getFullYear()}`;

        if (
          !showDateWarning &&
          formattedGetInfoDate !==
            `${data.date.getDate()}/${
              data.date.getMonth() + 1
            }/${data.date.getFullYear()}` &&
          getInfo.data.has_register
        ) {
          isShowDateWarning(true);
        } else {
          isShowDateWarning(false);

          const dateChangedValidation =
            formattedGetInfoDate !==
              `${data.date.getDate()}/${
                data.date.getMonth() + 1
              }/${data.date.getFullYear()}` && getInfo.data.has_supply;
          const communityChangedValidation =
            getInfo.data.community_slug !== data.community.slug &&
            !getInfo.data.is_type_editable;

          if (dateChangedValidation || communityChangedValidation) {
            if (dateChangedValidation) {
              toast.error(
                `${t(
                  "Message.You can't edit the date because it may conflict with supplied trees in tree nursery"
                )}`
              );
            }
            if (communityChangedValidation) {
              toast.error(`${t("Message.Edit community in event")}`);
            }
          } else {
            send({
              name_transes: translatableInput.name,
              date: `${data.date.getDate()}/${
                data.date.getMonth() + 1
              }/${data.date.getFullYear()}`,
              community: data.community.slug,
              organizers: coll,
              psa_students: data.psaStudent || 0,
              school_students: data.schoolStudent || 0,
              psa_tutors: data.psaTutor || 0,
              school_tutors: data.schoolTeacher || 0,
              farmers: data.farmers || 0,
              promoters: data.promoters || 0,
              junior_youth_parents: data.junior_youth_parents || 0,
              community_leaders: data.community_leaders || 0,
              other_participants: data.other || 0,
              planted_trees: data.type === "pt" ? data.plantedTrees : null,
              type: data.type.toLocaleUpperCase(),
              description_transes: translatableInput.description,
              video_files: videos,
              old_video_files_id: oldVideos.map((file) => {
                return file.id;
              }),
              comment_transes: translatableInput.comment,
            });
          }
        }
      }
    }
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addPlantingItem)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <p className="text-sm text-default-text mb-[6px] mt-7">
              {t("Tree Species.Name")} * <LanguagePoint lang={lang} />
            </p>
            <input
              name="name"
              onChange={nameChange}
              value={
                lang === "FR"
                  ? translatableInput.name.fr
                  : translatableInput.name.en
              }
              type="text"
              className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
              placeholder={t("Tree Species.Name") + ""}
            />
            {errors?.name?.type === "custom" && (
              <p className="error-text">{t("Message.Required field")}</p>
            )}
            <div className="flex flex-row flex-wrap justify-between mt-0 sm:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2 mt-7 md:mt-0">
                <p className="text-sm mb-[6px]">{t("Events.Event type")} *</p>
                <Controller
                  control={control}
                  rules={{ required: `${t("Message.Required field")}` }}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Events.Event type")}`}
                      </InputLabel>
                      <Select
                        style={{}}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="pt">
                          {t("Events.Planting tree")}
                        </MenuItem>
                        <MenuItem value="sd">
                          {t("Events.Seed collection")}
                        </MenuItem>
                        <MenuItem value="sl">
                          {t("Events.Seedling collection")}
                        </MenuItem>
                        <MenuItem value="td">
                          {t("Events.Tree distribution")}
                        </MenuItem>
                        <MenuItem value="ne">
                          {t("Events.Nursery establishment")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.type && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className=" w-full sm:w-1/2 sm:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Events.Date of event")} *
                </p>
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="date"
                  label={t("Events.Date of event")}
                  rules={{ required: `${t("Message.Required field")}` }}
                />

                {errors.date && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-0 sm:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Plantings.Country")}
                </p>
                <input
                  type="text"
                  value="Zambia"
                  readOnly
                  className="text-sm bg-ph-bg-gray text-white border border-[#eeecec] outline-none rounded-md w-full pl-4 py-3"
                />
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("PlantingFilter.Province")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="department"
                  selectOptions={selectOptions.department}
                  placeholder={t("PlantingFilter.Province")}
                  getOptionLabel={(option) => option.name}
                  onCustomChange={() => {
                    setValue("municipality", {
                      slug: "",
                      name: "",
                    });
                    setValue("community", {
                      slug: "",
                      name: "",
                    });
                  }}
                />
                {errors.department && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-0 sm:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p
                  className={`${
                    departmentValue?.slug === "" || !departmentValue?.slug
                      ? "text-ph-light-gray"
                      : "text-default-text"
                  } text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Plantings.District")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="municipality"
                  selectOptions={municipalities}
                  placeholder={t("Plantings.District")}
                  disabled={
                    departmentValue?.slug === "" || !departmentValue?.slug
                  }
                  getOptionLabel={(option) => option.name}
                  onCustomChange={() => {
                    setValue("community", {
                      slug: "",
                      name: "",
                    });
                  }}
                />
                {errors.municipality && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full sm:w-1/2 sm:pl-2">
                <p
                  className={`${
                    municipalityValue?.slug === "" || !municipalityValue?.slug
                      ? "text-ph-light-gray"
                      : "text-default-text"
                  } text-sm  mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Plantings.Community")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="community"
                  selectOptions={communities}
                  placeholder={t("Plantings.Community")}
                  disabled={
                    municipalityValue?.slug === "" || !municipalityValue?.slug
                  }
                  getOptionLabel={(option) => option.name}
                />
                {errors.community && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            {typeValue === "pt" && (
              <>
                <p className="text-sm text-default-text mb-[6px] mt-7">
                  {t("Plantings.Planted tree(s)")} *
                </p>
                <NumberField
                  onChange={plantedTreesChange}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  getValues={getValues}
                  clearError={clearErrors}
                  name="plantedTrees"
                  placeholder={t("Plantings.Planted tree(s)") + ""}
                  setValue={setValue}
                  register={register("plantedTrees", {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    min: {
                      value: 1,
                      message: `${t("Message.The minimum number is 1")}`,
                    },
                    required: `${t("Message.Required field")}`,
                  })}
                  styleClass="w-full sm:w-[48.5%]"
                  readOnl={false}
                />
                {errors.plantedTrees?.type === "min" && (
                  <p className="error-text">
                    {t("Message.The minimum number is 1")}
                  </p>
                )}
                {errors.plantedTrees?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
                {errors.plantedTrees && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </>
            )}

            <div className="relative">
              <div className="line-text">
                <p>{t("Plantings.Organizer")}</p>
                <span className="line"></span>
              </div>
              {organizers.map((orginizer, index: number) => (
                <div key={index} className="relative">
                  <Organizer
                    showError={showError}
                    addOrganizer={addOrganizer}
                    index={index}
                    organizer={orginizer}
                    nameChange={organizerNameChange}
                    typeChange={typeChange}
                    selectOptions={selectOptions}
                    selectedTypes={selectedTypes}
                  />
                  {organizers.length > 1 && index === organizers.length - 1 && (
                    <button
                      onClick={() => deleteOrganizer(index)}
                      type="button"
                      className="text-sm text-ph-red-warning mt-3 absolute right-0"
                    >
                      {t("PlantingFilter.Delete")}
                    </button>
                  )}
                </div>
              ))}
              <br />
              <button
                onClick={handleAddOrganizer}
                type="button"
                className={`text-sm font-medium ${
                  organizers.length >= 4
                    ? "text-ph-gray cursor-not-allowed"
                    : "text-ph-btn"
                }`}
                disabled={organizers.length >= 4}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                {t("Plantings.Add organizer")}
              </button>
            </div>
          </ThemeProvider>

          <p className="hr-lines">{t("PlantingFilter.Participants")} *</p>

          {errors.participants && (
            <p className="error-text">{t("Message.Zero not accepted")}</p>
          )}

          <Participants
            clearErrors={clearErrors}
            getValues={getValues}
            participantsChange={participantsChange}
            register={register}
            setValue={setValue}
          />

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("AddPlanting.Additional resources")}
            <span
              title={t("Plantings.Planting relevant resources hint") + ""}
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={resources}
            format="jpg, jpeg, png, txt, doc, docx, pdf, xls, xlsx (max 10 MB)"
            insertFile={insertResources}
            removeFile={removeResource}
          />

          {oldResource.map((or) => (
            <OldFile
              address={or.file_address}
              id={or.id}
              removeOldFile={removeOldResource}
              key={or.id}
              delete={true}
            />
          ))}
          <button
            onClick={() => {
              isVideo(true);
            }}
            type="button"
            className="text-sm text-ph-btn font-medium mb-6 mt-3"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            {t("Plantings.Add video link")}
          </button>

          {oldVideos.map((v, index: number) => (
            <Video
              key={index}
              delete={true}
              removeAction={deleteOldVideo}
              title={v.title}
              url={v.link}
              videoIndex={index}
            />
          ))}

          {videos.map((v, index: number) => (
            <Video
              key={index}
              delete={true}
              removeAction={deleteNewVideo}
              title={v.title}
              url={v.link}
              videoIndex={index}
            />
          ))}

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Tree Species.Description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descriptionChange}
            value={
              lang === "FR"
                ? translatableInput.description.fr
                : translatableInput.description.en
            }
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree Species.Description") + ""}
          />

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Plantings.Comment")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={commentChange}
            value={
              lang === "FR"
                ? translatableInput.comment.fr
                : translatableInput.comment.en
            }
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder={t("Plantings.Comment") + ""}
          />
          <button
            onClick={() => {
              isShowError(true);
              if (
                translatableInput.name.en.trim() === "" &&
                translatableInput.name.fr.trim() === ""
              ) {
                setError("name", {
                  type: "custom",
                  message: `${t("Message.Required field")}`,
                });
              }

              if (
                Number(getValues("psaStudent")) +
                  Number(getValues("schoolStudent")) +
                  Number(getValues("psaTutor")) +
                  Number(getValues("schoolTeacher")) +
                  Number(getValues("farmers")) +
                  Number(getValues("promoters")) +
                  Number(getValues("junior_youth_parents")) +
                  Number(getValues("community_leaders")) +
                  Number(getValues("other")) ===
                0
              ) {
                setError("participants", {
                  type: "custom",
                  message: `${t("Message.Zero not accepted")}`,
                });
              }
            }}
            type="submit"
            className={`${
              uploading ? "main-btn-disable" : "main-btn"
            } text-sm font-medium px-6 py-2 mt-5 float-right`}
            disabled={uploading ? true : false}
          >
            {uploading ? t("Message.Uploading files") : textButton}
          </button>
        </form>
      )}
      {video && (
        <VideoLinkPopup
          addVideo={insertVideo}
          closeVideoLink={() => {
            isVideo(false);
          }}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
      <PlantingDateWarning
        when={showDateWarning}
        onOK={() => {
          handleSubmit(addPlantingItem)();
          return true;
        }}
        onCancel={() => {
          isShowDateWarning(false);
          return true;
        }}
      />
    </Fragment>
  );
};

export default AddPlantingForm;
