import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface InfoBlockProps {
  icon: IconDefinition;
  label: string;
  value: string | null;
  isLeft: boolean;
}

const GeneralDetails: React.FC<InfoBlockProps> = ({
  icon,
  label,
  value,
  isLeft,
}) => (
  <div className="flex flex-row justify-start mb-4">
    <FontAwesomeIcon
      className="text-ph-gray w-[25px] h-[20px] mr-[16px] my-[11px]"
      icon={icon}
    />
    <div
      className={`${
        isLeft && "mr-[20px] md:mr-[135px]"
      } "flex h-[42px] flex-col items-start text-left"`}
    >
      <p className="text-sm font-regular  w-[89px] text-ph-gray">{label}</p>
      <p className="text-sm font-medium text-default-text mt-[6px]">{value}</p>
    </div>
  </div>
);

export default GeneralDetails;
