import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  ThemeProvider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomAutocomplete from "../../../Global/CustomAutocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { theme } from "../../../Register/AddRegister/FormTheme";
import SpeciesList from "../../../../type/speciesList";
import useAxios from "../../../../hook/useAxios";
import i18n from "../../../../i18n";
import NumberField from "../../../Global/NumberField";
import { Nursery } from "../../../../type/Nursery";
import { toast } from "react-toastify";

type FormValues = {
  species: SpeciesList;
  note: string;
  number: number;
  ageYear: string;
  ageMonth: string;
  nursery: Nursery;
};
const AddNurseryEstablishment = ({
  handleModalClose,
  send,
  getInfo,
  textButton,
  exist,
}: {
  handleModalClose: () => void;
  send: (data: any) => void;
  getInfo?: any;
  textButton: string;
  exist: SpeciesList;
}) => {
  const [treeSpecies, setTreeSpecies] = useState<SpeciesList[]>([]);
  const [nurseries, setNurseries] = useState<Nursery[]>([]);
  const { response: TreeSpecies } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: nurseriesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/nurseries/internal/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    register,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (getInfo) {
      setValue("species", {
        name_transes: getInfo.data.species_name_transes,
        slug: getInfo.data.species_slug,
      });
      setValue("ageYear", getInfo.data.age_year + "");
      setValue("ageMonth", getInfo.data.age_month + "");
      setValue("number", getInfo.data.number_of_trees);
      setValue("note", getInfo.data.note_transes.en);
      setValue("nursery", {
        name_transes: getInfo.data.nursery_name_transes,
        slug: getInfo.data.nursery_slug,
      });
    }
    if (exist.slug) {
      setValue("nursery", {
        name_transes: exist.name_transes,
        slug: exist.slug,
      });
    }
  }, [getInfo, setValue, exist]);

  useEffect(() => {
    if (TreeSpecies) {
      setTreeSpecies(TreeSpecies.data);
    }
    if (nurseriesRes) {
      setNurseries(nurseriesRes.data);
    }
  }, [TreeSpecies, nurseriesRes]);

  const addCollection = (data: FormValues) => {
    if (getInfo) {
      const speciesEdited = data.species.slug !== getInfo?.data?.species_slug;
      const ageEdited =
        data.ageYear !== getInfo?.data.age_year + "" ||
        data.ageMonth !== getInfo?.data.age_month + "";
      if (
        data.number !== getInfo?.data.number_of_trees &&
        data.number < getInfo?.data.distributed_number
      ) {
        toast.error(
          `${t(
            "Message.The number of trees can't be less than distributed trees"
          )}`
        );
      } else if (
        (speciesEdited || ageEdited) &&
        getInfo?.data.distributed_number > 0
      ) {
        if (speciesEdited) {
          toast.error(
            `${t(
              "Message.You can't edit species of the supplied trees. There are distributed trees for selected species and age."
            )}`
          );
        }
        if (ageEdited) {
          toast.error(
            `${t(
              "Message.You can't edit age of the supplied trees. There are distributed trees for selected species and age."
            )}`
          );
        }
      } else {
        send({
          age_year: Number(data.ageYear),
          age_month: Number(data.ageMonth),
          species: data.species.slug,
          number_of_trees: data.number,
          note_transes: JSON.stringify({ en: data.note, fr: "" }),
          date: getInfo.data.date,
        });
      }
    } else {
      send({
        age_year: Number(data.ageYear),
        age_month: Number(data.ageMonth),
        species: data.species.slug,
        number_of_trees: data.number,
        note_transes: JSON.stringify({ en: data.note, fr: "" }),
        nursery: data.nursery.slug,
      });
    }
  };
  const numberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("number");
    }
  };

  return (
    <Modal
      open
      onClose={() => handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-8 bg-ph-white w-[70%] lg:w-[615px] shadow-[0px_3px_6px_#00000029] rounded-md">
        <ThemeProvider theme={theme}>
          <div className="font-semibold text-default-text text-base">
            {t("Events.Nursery establishment detail")}
          </div>
          <form onSubmit={handleSubmit(addCollection)}>
            <div className="mt-8 flex flex-col md:flex-row md:items-start">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Tagging tool.Nursery")} *
                </p>
                {exist.slug || getInfo ? (
                  <input
                    type="text"
                    value={
                      i18n.language === "fr"
                        ? exist.name_transes?.fr === ""
                          ? exist.name_transes?.en
                          : exist.name_transes?.fr
                        : exist.name_transes?.en
                    }
                    placeholder={`${t("Tagging tool.Nursery")}`}
                    readOnly
                    className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-[13px] placeholder-white"
                  />
                ) : (
                  <>
                    <CustomAutocomplete
                      control={control}
                      rules={{
                        required: `${t("Message.Required field")}`,
                      }}
                      name="nursery"
                      selectOptions={nurseries}
                      placeholder={t("Tagging tool.Nursery")}
                      getOptionLabel={(option) =>
                        i18n.language === "fr"
                        ? option.name_transes?.fr === ""
                          ? option.name_transes?.en
                          : option.name_transes?.fr
                        : option.name_transes?.en
                      }
                    />
                    {errors.nursery && (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="w-full sm:w-[51.5%] sm:pl-[23px]">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Plantings.Species")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="species"
                  selectOptions={treeSpecies}
                  placeholder={t("Plantings.Species")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  }
                />
                {errors.species && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-7">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Events.Number")} *
                </p>
                <NumberField
                  onChange={numberChange}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  getValues={getValues}
                  clearError={clearErrors}
                  name="number"
                  placeholder={t("Events.Number")}
                  setValue={setValue}
                  register={register("number", {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    min: {
                      value: 1,
                      message: `${t("Message.The minimum number is 1")}`,
                    },
                    required: `${t("Message.Required field")}`,
                  })}
                  readOnl={false}
                />
                {errors.number?.type === "min" && (
                  <p className="error-text">
                    {t("Message.The minimum number is 1")}
                  </p>
                )}
                {errors.number?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
                {errors.number && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-[51.5%] sm:pl-[23px]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree inventory.Initial age")}*
                </p>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="ageYear"
                    rules={{ required: `${t("Message.Required field")}` }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Register.Year")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "48px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <span className="px-2 text-default-text text-xs">
                    &#9866;
                  </span>
                  <Controller
                    control={control}
                    name="ageMonth"
                    rules={{ required: `${t("Message.Required field")}` }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Register.Month")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "48px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                {(errors.ageYear || errors.ageMonth) && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="w-full sm:pl-2 mt-7">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Tree inventory.Note")}
              </p>
              <textarea
                rows={4}
                {...register("note")}
                className="input-field resize-none text-sm pl-4 py-[10px] placeholder-ph-light-gray"
                placeholder={t("Tree inventory.Note") + ""}
              />
            </div>
            <div className="mt-10 flex flex-row justify-end">
              <button
                onClick={() => {
                  handleModalClose();
                }}
                type="button"
                className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 font-medium"
              >
                {t("Message.Cancel")}
              </button>
              <button
                type="submit"
                className="main-btn px-[23px] py-[10px] text-sm"
              >
                {textButton}
              </button>
            </div>
          </form>
        </ThemeProvider>
      </div>
    </Modal>
  );
};

export default AddNurseryEstablishment;
