import React, { useState } from "react";
import AddPlantingForm from "./AddPlanting/AddPlantingForm";
import PlantingView from "./ViewPlanting/PlantingView";
import { Department } from "../../type/department";
import { useTranslation } from "react-i18next";
import SeedCollectionDetail from "./EventDetails/SeedCollection/SeedCollectionDetail";
import TreeDistributionDetail from "./EventDetails/TreeDistribution/TreeDistributionDetail";
import NurseryEstablishmentDetail from "./EventDetails/NurseryEstablishment/NurseryEstablishmentDetail";

interface EventFormProps {
  mode: "add" | "edit" | "view";
  selectOption?: {
    department: Department[];
    grp: Department[];
    ins: Department[];
    ind: Department[];
    fml: Department[];
  };
  fetchData?: (data: any) => void;
  response?: any;
  addOrganizerName?: (type: string, value: Department) => void;
  getEditResponse?: any;
}

const EventForm: React.FC<EventFormProps> = ({
  mode,
  selectOption,
  fetchData,
  addOrganizerName,
  getEditResponse,
  response,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<"evt" | "detail">("evt");
  const [type, setType] = useState<string>("");

  const handleEventType = (type: string) => {
    setType(type);
  };

  const handleTabChange = (newTab: string) => {
    setTab(newTab as "evt" | "detail");
  };

  const renderEventTab = () => {
    switch (mode) {
      case "add":
        return (
          <AddPlantingForm
            addOrganizer={addOrganizerName!}
            selectOptions={selectOption!}
            send={fetchData!}
            sendResponse={response}
            textButton={t("Events.Save & Proceed to details")}
            eventType={handleEventType}
            onTabChange={handleTabChange}
          />
        );
      case "edit":
        return (
          <AddPlantingForm
            addOrganizer={addOrganizerName!}
            selectOptions={selectOption!}
            send={fetchData!}
            sendResponse={response}
            getInfo={getEditResponse}
            textButton={t("Tree Species.Update")}
            eventType={handleEventType}
            onTabChange={handleTabChange}
          />
        );
      case "view":
        return <PlantingView getInfo={getEditResponse} />;
    }
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-row">
        <div className="flex flex-row cursor-pointer">
          <p
            className={`cursor-pointer ${
              tab === "evt"
                ? "text-ph-btn border-2 border-white bg-white rounded-t-[8px]"
                : mode !== "add"
                ? "text-default-text border-2 border-ph-map-bg"
                : "text-gray-400 border-2 border-ph-map-bg"
            } py-4 px-2 h-[57px] w-[92px] font-medium text-[14px] text-center`}
            onClick={() => {
              if (mode !== "add") {
                setTab("evt");
              }
            }}
          >
            {t("Events.Event")}
          </p>
          <p
            className={`cursor-pointer flex items-center justify-center ${
              tab === "detail"
                ? "text-ph-btn border-2 border-white bg-white rounded-t-[8px]"
                : mode !== "add" && getEditResponse?.data.type !== "PT"
                ? "text-default-text border-2 border-ph-map-bg"
                : "text-gray-400 border-2 border-ph-map-bg"
            } py-4 px-2 h-[57px] w-[130px] text-[14px] text-center`}
            onClick={() => {
              if (
                getEditResponse?.data &&
                getEditResponse?.data.type !== "PT"
              ) {
                setTab("detail");
              }
            }}
          >
            {t("Events.Event detail")}
          </p>
        </div>
      </div>
      {tab === "evt" ? (
        <div className="bg-white p-7">
          <div className="flex flex-wrap justify-between mt-4">
            <div className="w-full h-fit lg:w-[50%] bg-white rounded-md p-5 relative">
              {renderEventTab()}
            </div>
            <div className="w-full h-fit lg:w-[48%] bg-white rounded-md p-8 mt-4 lg:mt-0">
              <img
                src="/img/Add Planting.svg"
                alt="addEvent"
                className="mt-4 xl:ml-10"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white p-7 overflow-x-auto">
          <div className="flex bg-ph-white mr-4 min-w-[1000px]">
            {tab === "detail" &&
            (type === "ne" || getEditResponse?.data.type === "NE") ? (
              <div className="w-full bg-white rounded-md relative">
                <NurseryEstablishmentDetail
                  id={getEditResponse?.data.slug || response?.data.slug}
                />
              </div>
            ) : tab === "detail" &&
              (type === "td" || getEditResponse?.data.type === "TD") ? (
              <div className="w-full bg-white rounded-md relative">
                <TreeDistributionDetail
                  id={getEditResponse?.data.slug || response?.data.slug}
                />
              </div>
            ) : tab === "detail" &&
              (type === "sd" || getEditResponse?.data.type === "SD") ? (
              <div className="w-full bg-white rounded-md relative">
                <SeedCollectionDetail
                  id={getEditResponse?.data.slug || response?.data.slug}
                  type="seed"
                />
              </div>
            ) : tab === "detail" &&
              (type === "sl" || getEditResponse?.data.type === "SL") ? (
              <div className="w-full bg-white rounded-md relative">
                <SeedCollectionDetail
                  id={getEditResponse?.data.slug || response?.data.slug}
                  type="seedling"
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventForm;
