import { Controller } from "react-hook-form";
import {
  Checkbox,
  TextField,
  Autocomplete,
  autocompleteClasses,
} from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { colors } from "../../styles/colors";
import { useState } from "react";

type CustomChangeValue<T> = T | T[] | null;

interface ReusableAutocompleteProps<T> {
  control: any;
  rules?: any;
  name: string;
  selectOptions: T[];
  placeholder: string;
  disabled?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  getOptionLabel: (option: T) => string;
  renderOption?: (
    props: any,
    option: T,
    params: { selected: boolean }
  ) => JSX.Element;
  onCustomChange?: (newValue: CustomChangeValue<T>) => void;
  isMulti?: boolean;
  defaultValue?: T | T[];
  readonly?: boolean;
}

const autocompleteStyles = (readonly: boolean, disabled: boolean) => ({
  width: "100%",
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: "none",
    color: disabled && readonly && "#FFFFFF",
  },
  color: readonly ? colors.white : undefined,
  border: "1px solid #eeecec",
  borderRadius: "4px",
  "& fieldset": {
    border: "none",
  },
  "& .Mui-focused": {
    outline: "1px solid #439FC6",
  },
  "& .MuiChip-label": {
    color: disabled || readonly ? "#FFFFFF" : undefined,
  },
  "& .MuiFormControl-root": {
    "& .Mui-disabled": {
      backgroundColor: disabled && readonly ? "#A2A1A1" : undefined,
      opacity: 100,
    },
  },
  "& .MuiInputBase-root": {
    color: readonly ? colors.white : colors.default_text,
  },
});

function CustomAutocomplete<T>({
  control,
  rules,
  name,
  selectOptions,
  placeholder,
  disabled = false,
  icon = <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />,
  checkedIcon = <CheckBoxIcon sx={{ color: "#066993" }} fontSize="small" />,
  getOptionLabel,
  renderOption,
  onCustomChange,
  isMulti = false,
  defaultValue,
  readonly = false,
}: ReusableAutocompleteProps<T>) {
  const [selectedItems, setSelectedItems] = useState<T[]>(
    (defaultValue as T[]) || []
  );

  const handleChange = (newValue: T | T[] | null) => {
    if (Array.isArray(newValue)) {
      const deduplicated = Array.from(
        new Set(newValue.map((item) => getOptionLabel(item)))
      ).map((label) => newValue.find((item) => getOptionLabel(item) === label));
      setSelectedItems(deduplicated as T[]);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          popupIcon={icon}
          // @ts-ignore
          multiple={isMulti}
          disabled={disabled}
          limitTags={1}
          id="reusable-autocomplete"
          options={selectOptions}
          disableCloseOnSelect={isMulti}
          getOptionLabel={getOptionLabel}
          sx={autocompleteStyles(readonly, disabled)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={isMulti && value?.length > 0 ? "" : placeholder}
            />
          )}
          renderOption={(props, option: any, { selected }) => {
            const isSelectedU = selectedItems.some(
              (item: T) => getOptionLabel(item) === getOptionLabel(option)
            );
            const isSelected =
              isMulti && isSelectedU
                ? (value || []).some(
                    (v: T) => getOptionLabel(v) === getOptionLabel(option)
                  )
                : getOptionLabel(value || {}) === getOptionLabel(option);

            return (
              <li {...props}>
                {isMulti && (
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={checkedIcon}
                    style={{
                      marginRight: 8,
                    }}
                    checked={isSelected}
                  />
                )}
                {getOptionLabel(option)}
              </li>
            );
          }}
          // @ts-ignore
          onChange={(event, newValue) => {
            const deduplicatedValue = Array.isArray(newValue)
              ? Array.from(
                  new Set(newValue.map((item) => getOptionLabel(item)))
                ).map((label) =>
                  newValue.find((item) => getOptionLabel(item) === label)
                )
              : newValue;
            onChange(deduplicatedValue);
            handleChange(deduplicatedValue as T | T[] | null);
            onCustomChange && onCustomChange(newValue);
          }}
          value={isMulti ? value || [] : value || null}
          readOnly={readonly}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
