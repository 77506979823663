import React from "react";
import TranslationField from "../../../type/translationField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const InfoPopover: React.FC<{
  desc: TranslationField;
  index?: boolean;
  scroll?: boolean;
  reg?: boolean;
}> = ({ index, desc, scroll, reg }) => {
  const text = desc.fr === "" ? desc.en : desc.fr;
  const { i18n } = useTranslation();
  return (
    <div className="desc-btn ">
      <FontAwesomeIcon
        className="desc-icon w-4 h-4 text-default-text "
        icon={faInfoCircle}
      />
      <div
        className={`desc-text shadow-2xl ${reg ? "right-[-200px]" : ""} ${
          scroll
            ? "top-[20px]"
            : "bottom-[0px]" && index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        <p>{i18n.language === "fr" ? text : desc.en}</p>
      </div>
    </div>
  );
};

export default InfoPopover;
