import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFileZipper } from "@fortawesome/pro-light-svg-icons";

const FileAccepted: React.FC<{
  fileIndex: number;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  fileType: string;
  removeAction: (fileIndex: number) => void;
}> = ({ fileIndex, fileName, fileSize, fileUrl, fileType, removeAction }) => {
  const { t } = useTranslation();

  const removeFile = () => {
    removeAction(fileIndex);
  };
  return (
    <div className="w-full flex items-center rounded-md bg-ph-bg-light-white border border-[#EEECEC] relative mb-2">
      {fileType.includes("image") && (
        <img alt="img" src={fileUrl} className="w-[60px] h-[60px]" />
      )}
      {fileType.includes("pdf") && (
        <img alt="img" src="/img/file-pdf.svg" className="w-[60px] h-[60px]" />
      )}
      {(fileType.includes("wordprocessingml") ||
        fileType.includes("msword")) && (
        <img alt="img" src="/img/file-doc.svg" className="w-[60px] h-[60px]" />
      )}
      {(fileType.includes("spreadsheetml") ||
        fileType.includes("ms-excel") ||
        fileType.includes("text/csv")) && (
        <img
          alt="img"
          src="/img/file-excel.svg"
          className="w-[60px] h-[60px]"
        />
      )}
      {fileType.includes("text/plain") && (
        <img alt="img" src="/img/file-txt.svg" className="w-[60px] h-[60px]" />
      )}
      {fileType.includes("application/zip") && (
        <FontAwesomeIcon
          icon={faFileZipper}
          className="mx-2 w-[50px] h-[50px]"
        />
      )}
      <div className="ml-3">
        <p className="text-default-text text-sm font-medium h-5 w-[120px] md:w-[220px] whitespace-nowrap overflow-hidden text-ellipsis">
          {fileName}
        </p>
        <p className="text-default-text text-xs">{fileSize / 1000} kB</p>
      </div>
      <p
        onClick={removeFile}
        className="absolute right-3 text-default-text text-sm hover:text-ph-red-warning cursor-pointer"
      >
        <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
        {t("PlantingFilter.Delete")}
      </p>
    </div>
  );
};

export default FileAccepted;
