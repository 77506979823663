import { ThemeProvider } from "@emotion/react";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { TaggingToolFilterDelete, TaggingToolFilterSet } from "../../../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import useAxios from "../../../hook/useAxios";
import SpeciesList from "../../../type/speciesList";
import { ResponsibleNames } from "../../../type/responsibleNames";
import { TaggingToolFilterAction } from "../../../store/taggingToolFilter-slice";
import { Community } from "../../../type/community";
import { Nursery } from "../../../type/Nursery";

type FormValues = {
  community: Community;
  responsible: ResponsibleNames;
  nursery: Nursery;
  species: SpeciesList;
  isUsed: string;
};

const TaggingToolFilterBox = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset, resetField } = useForm<FormValues>();
  const isFilterSet = useSelector(TaggingToolFilterSet);
  const deleteItem = useSelector(TaggingToolFilterDelete);
  const [selectOption, setSelectOption] = useState<{
    responsible: ResponsibleNames[];
    nurseries: Nursery[];
    species: SpeciesList[];
    communities: Community[];
  }>({
    responsible: [],
    nurseries: [],
    species: [],
    communities: [],
  });

  const { response: communitiesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/communities/all",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: nurseriesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/nurseries/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: speciesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: responsibleRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/responsible_names/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (communitiesRes) {
      if (communitiesRes.data) {
        setSelectOption((prevState) => ({
          ...prevState,
          communities: communitiesRes.data,
        }));
      }
    }
    if (responsibleRes) {
      if (responsibleRes.data) {
        setSelectOption((prevState) => ({
          ...prevState,
          responsible: responsibleRes.data,
        }));
      }
    }
    if (nurseriesRes) {
      if (nurseriesRes.data) {
        setSelectOption((prevState) => ({
          ...prevState,
          nurseries: nurseriesRes.data,
        }));
      }
    }
    if (speciesRes) {
      if (speciesRes.data) {
        setSelectOption((prevState) => ({
          ...prevState,
          species: speciesRes.data,
        }));
      }
    }
  }, [speciesRes, nurseriesRes, communitiesRes, responsibleRes]);

  const [advanceFilter, isAdvanceFilter] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (deleteItem === "community") {
      resetField("community");
    } else if (deleteItem === "responsible") {
      resetField("responsible");
    } else if (deleteItem === "nursery") {
      resetField("nursery");
    } else if (deleteItem === "species") {
      resetField("species");
    } else if (deleteItem === "isUsed") {
      resetField("isUsed");
    } else if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);

  const applyFilter = (data: FormValues) => {
    dispatch(
      TaggingToolFilterAction.setFilter({
        community: data?.community || "",
        responsible: data?.responsible || "",
        nursery: data?.nursery || "",
        species: data?.species || "",
        isUsed: data?.isUsed || "",
      })
    );
    dispatch(TaggingToolFilterAction.setURL());
    dispatch(TaggingToolFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(TaggingToolFilterAction.clearFilter());
    dispatch(TaggingToolFilterAction.setURL());
  };
  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <span
          title={t("Tree Species.Advanced filter") + ""}
          className={`${advanceFilter ? "text-ph-grayD" : "text-ph-btn"
            } float-right  cursor-pointer`}
          onClick={() => {
            isAdvanceFilter(!advanceFilter);
          }}
        >
          <FontAwesomeIcon
            className="w-[21px] h-[21px]"
            icon={advanceFilter ? faXmark : faFilterList}
          />
        </span>
        <br />
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm text-default-text mb-[6px]">
                {t("Plantings.Community")}
              </p>
              <CustomAutocomplete
                control={control}
                name="community"
                selectOptions={selectOption.communities}
                placeholder={t("Plantings.Community")}
                getOptionLabel={(option) =>
                  option.name + ` (${option.initials})`
                }
              />
            </div>
            <div className="w-full lg:w-[48%] md:mt-5 lg:mt-0">
              <p className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}>
                {t("Register.Responsible")}
              </p>
              <CustomAutocomplete
                control={control}
                name="responsible"
                selectOptions={selectOption.responsible}
                placeholder={t("Register.Responsible")}
                getOptionLabel={(option) =>
                  option?.full_name + ` (${option?.initials})`
                }
              />
            </div>
          </div>
          {advanceFilter && (
            <Fragment>
              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] md:mt-5">
                <div className="w-full lg:w-[48%] md:mt-5 lg:mt-0">
                  <p
                    className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                  >
                    {t("Tagging tool.Nursery")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="nursery"
                    selectOptions={selectOption.nurseries}
                    placeholder={t("Tagging tool.Nursery")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr"
                        ? option.name_transes?.fr === ""
                          ? option.name_transes?.en
                          : option.name_transes?.fr
                        : option.name_transes?.en
                    }
                  />
                </div>
                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tagging tool.Species")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="species"
                    selectOptions={selectOption.species}
                    placeholder={t("Tagging tool.Species")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr"
                        ? option.name_transes?.fr === ""
                          ? option.name_transes?.en
                          : option.name_transes?.fr
                        : option.name_transes?.en
                    }
                  />
                </div>
                <div className="w-full lg:w-[48%] mt-5">
                  <p className="text-sm mb-[6px]">{t("Tagging tool.Used")}</p>
                  <Controller
                    control={control}
                    name="isUsed"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Tagging tool.Used")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "38px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="all">{t("Statistics.All")}</MenuItem>
                          <MenuItem value="true">{t("Message.Yes")}</MenuItem>
                          <MenuItem value="false">{t("Message.No")}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}
            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default TaggingToolFilterBox;
