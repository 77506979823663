import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { ThemeProvider } from "@emotion/react";
import { useEffect, useState } from "react";
import { plantingTheme } from "../Register/RegisterList/PlantingTheme";
import { useDispatch, useSelector } from "react-redux";
import { mapFilterDelete, mapFilterSet, speciesList } from "../../store";
import { selectFieldAction } from "../../store/selectFields-slice";
import useAxios from "../../hook/useAxios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SpeciesList from "../../type/speciesList";
import { mapFilterAction } from "../../store/mapFilter-slice";
import { Department } from "../../type/department";
import CustomDate from "../Global/CustomDate";
import CustomAutocomplete from "../Global/CustomAutocomplete";

type FormValues = {
  dateFrom: Date;
  dateTo: Date;
  treeSpecies: SpeciesList;
  department: Department;
};

const MapFilter = () => {
  const [advanceFilter, isAdvanceFilter] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const { t, i18n } = useTranslation();
  const { response: speRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
    reset,
    resetField,
  } = useForm<FormValues>();
  const isFilterSet = useSelector(mapFilterSet);
  const treeSpeciesList = useSelector(speciesList);
  const deleteItem = useSelector(mapFilterDelete);

  const dispatch = useDispatch();
  useEffect(() => {
    if (deleteItem === "date") {
      resetField("dateTo");
      resetField("dateFrom");
    } else if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);
  useEffect(() => {
    if (departmentRes !== null) {
      setDepartments(departmentRes.data);
    }
    if (speRes !== null) {
      dispatch(selectFieldAction.setSpeciesList(speRes.data));
    }
  }, [departmentRes, speRes, dispatch]);

  const applyFilter = (data: FormValues) => {
    const dateT =
      !data.dateTo || !data.dateTo?.getDate()
        ? ""
        : `${data.dateTo.getDate()}/${
            data.dateTo.getMonth() + 1
          }/${data.dateTo.getFullYear()}`;
    const dateF =
      !data.dateFrom || !data.dateFrom?.getDate()
        ? ""
        : `${data.dateFrom.getDate()}/${
            data.dateFrom.getMonth() + 1
          }/${data.dateFrom.getFullYear()}`;
    if (data.dateFrom > data.dateTo) {
      setError("dateFrom", {
        type: "custom",
        message: `${t("Message.Invalid date filter error")}`,
      });
      return;
    }
    dispatch(
      mapFilterAction.setFilter({
        dateTo: dateT,
        dateFrom: dateF,
        department: data.department,
        treeSpecies: data.treeSpecies,
      })
    );
    dispatch(mapFilterAction.setURL());
    dispatch(mapFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(mapFilterAction.clearFilter());
    dispatch(mapFilterAction.setURL());
  };

  const getLabel = (option: any) => {
    const { en, fr } = option.name_transes || {};
    const { date } = option;

    if (i18n.language === "fr") {
      if (fr === "") {
        return date ? `${en} - ${date}` : en;
      } else {
        return date ? `${fr} - ${date}` : fr;
      }
    } else {
      return date ? `${en} - ${date}` : en;
    }
  };

  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <span
          title={t("Tree Species.Advanced filter") + ""}
          className={`${
            advanceFilter ? "text-[#989898]" : "text-ph-toggle-text-blue"
          } float-right  cursor-pointer`}
          onClick={() => {
            isAdvanceFilter(!advanceFilter);
          }}
        >
          <FontAwesomeIcon
            className="w-[21px] h-[21px]"
            icon={advanceFilter ? faXmark : faFilterList}
          />
        </span>
        <br />
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px]">
                {t("Plantings.Date of planting")}
              </p>
              <div className="flex items-center">
                <CustomDate
                  control={control}
                  name="dateFrom"
                  label={t("PlantingFilter.From")}
                />
                <span className="px-2 text-default-text text-xs">&#9866;</span>
                <CustomDate
                  control={control}
                  name="dateTo"
                  label={t("PlantingFilter.To")}
                />
              </div>
              {errors?.dateFrom?.type === "custom" && (
                <p className="error-text">
                  {t("Message.Invalid date filter error")}
                </p>
              )}
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm mb-[6px]">{t("PlantingFilter.Trees")}</p>
              <CustomAutocomplete
                control={control}
                name="treeSpecies"
                selectOptions={treeSpeciesList}
                placeholder={t("Navbar.Tree species")}
                getOptionLabel={getLabel}
              />
            </div>
          </div>
          {advanceFilter && (
            <div className="w-full sm:w-[80%] md:w-[65%] lg:w-[31.2%] mt-5">
              <p className="text-sm mb-[6px]">
                {t("PlantingFilter.Province")}{" "}
              </p>
              <CustomAutocomplete
                control={control}
                name="department"
                selectOptions={departments}
                placeholder={t("PlantingFilter.Province")}
                getOptionLabel={(option) => option.name}
              />
            </div>
          )}
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-[#F6F6F6] mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}

            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default MapFilter;
