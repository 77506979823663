import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { speciesFilters, speciesFilterSet, speciesURL } from "../../../store";
import { speciesFilterAction } from "../../../store/speciesFilter-slice";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import SpeciesFilterBox from "./SpeciesFilterBox";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import useAxios from "../../../hook/useAxios";
import { TreeSpeciesRecord } from "../../../type/treeSpecies";
import GenericTable from "../../Global/GenericTable";
import SpeciesTableItem from "./SpeciesTableItem";

const TreeSpecies = () => {
  const [speciesList, setSpeciesList] = useState<TreeSpeciesRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const dispatch = useDispatch();
  const url = useSelector(speciesURL);
  const filters = useSelector(speciesFilters);
  const isFilter = useSelector(speciesFilterSet);
  const { i18n, t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setSpeciesList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tree Species.Tree species")}
        </h1>
        <Link
          to="/setting/tree-species/add-species"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Tree Species.Add tree species")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree Species.Tree species"),
            url: "",
          },
        ]}
      />
      <SpeciesFilterBox />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <h1 className="text-[#979797] font-medium text-sm">
            {t("Tree Species.Applied Filters")}
          </h1>
          <div className="flex flex-wrap items-center">
            {filters.type && (
              <AppliedItem
                type={t("Tree Species.Type")}
                value={
                  i18n.language === "fr" ? filters.type.fr : filters.type.en
                }
                removeFilter={() => {
                  dispatch(speciesFilterAction.removeType());
                  dispatch(speciesFilterAction.setURL());
                  dispatch(speciesFilterAction.isFilterSet());
                }}
              />
            )}
            {filters.abundance !== "" && (
              <AppliedItem
                type={t("Tree Species.Abundance")}
                value={`${filters.abundance === "frq" ? "Frequent" : ""} ${
                  filters.abundance === "scr" ? "Scarce" : ""
                } ${filters.abundance === "nkn" ? "Not known" : ""}`}
                removeFilter={() => {
                  dispatch(speciesFilterAction.removeAbundance());
                  dispatch(speciesFilterAction.setURL());
                  dispatch(speciesFilterAction.isFilterSet());
                }}
              />
            )}
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={speciesList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Tree Species.Name"), value: "name" },
            {
              name: t("Tree Species.Scientific name"),
              value: "scientific_name",
            },
            {
              name: t("Tree Species.Botanical family"),
              value: "botanical_family",
            },
            { name: t("Tree Species.Type"), value: "type" },
            { name: t("Tree Species.Abundance"), value: "abundance" },
            { name: t("Province.Status"), value: "is_active" },
          ]}
          renderItem={(treeRecord, fetchData, lastIndex) => (
            <SpeciesTableItem
              speciesItem={treeRecord}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default TreeSpecies;
