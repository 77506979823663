import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Status from "../../Global/Status";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAxios from "../../../hook/useAxios";
import { ResponsibleType } from "../../../type/responsibleType";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import { toast } from "react-toastify";

type FormValues = {
  category: string;
  type: ResponsibleType | null;
  firstName: string;
  lastName: string;
  initials: string;
  phone: number;
};
const AddResponsible = ({
  handleModalClose,
  send,
  getInfo,
  textButton,
  title,
}: {
  handleModalClose: () => void;
  send: (data: any) => void;
  getInfo?: any;
  textButton: string;
  title: string;
}) => {
  const [active, isActive] = useState<boolean>(true);
  const [selectOption, setSelectOption] = useState<{
    psa: ResponsibleType[];
    ins: ResponsibleType[];
    ind: ResponsibleType[];
    fml: ResponsibleType[];
  }>({
    ind: [],
    ins: [],
    psa: [],
    fml: [],
  });

  const { response: psaRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=grp",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ind",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ins",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: funRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=fml",
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (psaRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          psa: psaRes.data,
        };
      });
    }
    if (indRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ind: indRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
    if (funRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          fml: funRes.data,
        };
      });
    }
  }, [indRes, insRes, psaRes, funRes]);

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useForm<FormValues>({
    defaultValues: {
      category: "",
      type: null,
    },
  });

  const { t } = useTranslation();

  const firstName = watch("firstName", "");
  const lastName = watch("lastName", "");

  useEffect(() => {
    if (getInfo) {
      setValue("firstName", getInfo.data.first_name);
      setValue("lastName", getInfo.data.last_name);
      setValue("initials", getInfo.data.initials);
      setValue("phone", getInfo.data.phone);
      setValue("type", {
        id: getInfo.data.responsible_type_id,
        slug: getInfo.data.responsible_type_slug,
        name: getInfo.data.responsible_type_name,
      });
      setValue("category", getInfo.data.category);
      isActive(getInfo.data.is_active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (firstName && lastName && !getInfo) {
      const initials =
        firstName.charAt(0).toUpperCase() +
        lastName.substring(0, 2).toUpperCase();
      setValue("initials", initials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, getInfo]);

  const addResponsible = (data: FormValues) => {
    if (
      getInfo &&
      data.initials.toUpperCase() !== getInfo.data.initials &&
      !getInfo.data.is_initials_editable
    ) {
      toast.error(
        t(
          "Message.You can't edit initials. Some tags belong to this responsible."
        )
      );
    } else {
      send({
        category: data.category,
        type:
          data.category === "ind" ? selectOption.ind[0].slug : data.type?.slug,
        first_name: data.firstName,
        last_name: data.lastName,
        initials: data.initials.toUpperCase(),
        is_active: active,
        phone: data.phone,
      });
    }
  };

  return (
    <Modal
      open
      onClose={() => handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-8 bg-ph-white w-[90%] h-[80%] md:w-[70%] lg:w-[48%] overflow-auto">
        <div className="font-semibold text-default-text text-base">{title}</div>
        <form onSubmit={handleSubmit(addResponsible)}>
          <ThemeProvider theme={theme}>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Category")} *
                </p>
                <Controller
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && t("Register.Category")}
                      </InputLabel>
                      <Select
                        style={{ height: "48px" }}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                          resetField("type");
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem value="fml">{t("Register.Family")}</MenuItem>
                        <MenuItem value="grp">{t("Register.Group")}</MenuItem>
                        <MenuItem value="ind">
                          {t("Register.Individual")}
                        </MenuItem>
                        <MenuItem value="ins">
                          {t("Register.Institution")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.category && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
                <p
                  className={`${
                    getValues("category") === undefined
                      ? "text-ph-light-gray"
                      : "text-default-text"
                  } text-sm mb-[6px]`}
                >
                  {t("Tree Species.Type")} *
                </p>
                <CustomAutocomplete
                  control={control}
                  rules={{
                    validate: (value: any) => {
                      if (
                        getValues("category") !== "ind" &&
                        (!value || value.slug === "")
                      ) {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  name="type"
                  selectOptions={
                    getValues("category") === "fml"
                      ? selectOption.fml
                      : getValues("category") === "ins"
                      ? selectOption.ins
                      : selectOption.psa
                  }
                  placeholder={t("Tree Species.Type")}
                  disabled={
                    getValues("category") && getValues("category") !== "ind"
                      ? false
                      : true
                  }
                  getOptionLabel={(option) => option.name}
                />
                {errors.type && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-[48.5%]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Users.First name")}*
                </p>
                <input
                  {...register("firstName", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Users.First name") + ""}
                />
                {errors.firstName && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-[48.5%] mt-7 sm:mt-0">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Users.Last name")}*
                </p>
                <input
                  {...register("lastName", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Users.Last name") + ""}
                />
                {errors.lastName && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row flex-wrap justify-between items-start mt-7">
              <div className="flex w-full flex-col sm:w-[48.5%]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Community.Initials")}*
                </p>
                <input
                  {...register("initials", {
                    required: `${t("Message.Required field")}`,
                    maxLength: {
                      value: 3,
                      message: `${t("Message.Initials must be 3 characters.")}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("Message.Initials must be 3 characters.")}`,
                    },
                  })}
                  maxLength={3}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Community.Initials") + ""}
                />
                {errors.initials && (
                  <p className="error-text">{errors.initials.message}</p>
                )}
              </div>
              <div className="flex flex-col w-full sm:w-[48.5%]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Users.Phone")}
                </p>
                <input
                  {...register("phone")}
                  type="number"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Users.Phone") + ""}
                />
              </div>
            </div>
            <div className="mt-7">
              <Status
                active={active}
                isActive={(value) => {
                  isActive(value);
                }}
              />
            </div>
            <div className="mt-10 flex flex-row justify-end">
              <button
                onClick={() => {
                  handleModalClose();
                }}
                type="button"
                className="text-default-text text-sm rounded-3xl px-6 py-2 mt-5 font-medium"
              >
                {t("Message.Cancel")}
              </button>
              <button
                type="submit"
                className="main-btn text-sm px-6 py-2 mt-5 float-right"
              >
                {textButton}
              </button>
            </div>
          </ThemeProvider>
        </form>
      </div>
    </Modal>
  );
};

export default AddResponsible;
