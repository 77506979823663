import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../../Register/AddRegister/FormTheme";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDate from "../../../../Global/CustomDate";
import SelectLanguage from "../../../../Global/SelectLanguage";
import CustomAutocomplete from "../../../../Global/CustomAutocomplete";
import i18n from "../../../../../i18n";
import SpeciesList from "../../../../../type/speciesList";
import LanguagePoint from "../../../../Global/LanguagePoint";
import TranslationField from "../../../../../type/translationField";
import moment from "moment";
import NumberField from "../../../../Global/NumberField";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AddDistributeSuccess from "../../../../ErrorAndAlert/AddDistributeSuccess";
import useAxios from "../../../../../hook/useAxios";
import Warning from "../../../../ErrorAndAlert/Warning";
import AddDistributeButton from "../AddDistributeButton";

type FormValues = {
  date: Date;
  treeSpecies: SpeciesList;
  age: {
    slug: string;
    label: string;
  };
  distributed_trees: number;
  remaining_tree: number;
  note_transes: string;
  supply_slug: string;
  order_id: string;
};

const DistributeTreeForm: React.FC<{
  nurseryCode: any;
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  id: string;
}> = ({ nurseryCode, send, sendResponse, id, getInfo }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("EN");
  const [translatableInput, setTranslatableInput] = useState<{
    note: TranslationField;
  }>({
    note: { en: "", fr: "" },
  });
  const [treeSpeciesList, setTreeSpeciesLis] = useState<any[]>([]);
  const [formattedAge, setFormattedAge] = useState("");
  const [selectedSpecies, setSelectedSpecies] = useState<any>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [remainingTree, setRemainingTree] = useState<number>(0);
  const history = useHistory();
  const [action, setAction] = useState("");
  const [ages, setAges] = useState<
    { slug: string; label: string; date?: any }[]
  >([]);
  const [distributedTreeSelectedState, setDistributedTreeSelectedState] =
    useState(0);
  const [dirty, isDirty] = useState<boolean>(true);
  const [remainingTreeSpecies, setRemainingTreeSpecies] = useState(0);

  const {
    handleSubmit,
    setValue,
    clearErrors,
    register,
    getValues,
    setError,
    watch,
    control,
    resetField,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { remaining_tree: 0 },
  });
  const treeSpeciesValue = watch("treeSpecies");
  const dateSelected = watch("date");
  const ageSelected = watch("age");
  const distributedTreeSelected = watch("distributed_trees");

  const apiUrl =
    process.env.REACT_APP_API_URL + `/api/admin/nurseries/${id}/supply/all`;
  const finalUrl =
    sendResponse || getInfo
      ? `${apiUrl}/?order_id=${
          sendResponse ? sendResponse?.data?.order_id : getInfo.data?.order_id
        }`
      : apiUrl;
  const { response: treeSpeciesRes, fetchData: getTreeSpecies } = useAxios(
    finalUrl,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (treeSpeciesRes) {
      const filteredSpecies = treeSpeciesRes.data.filter(
        (species: { ages: any[] }) =>
          species.ages.some(
            (age) => !age.is_selected && age.remaining_items > 0
          )
      );

      setTreeSpeciesLis(filteredSpecies);
    }
    if (treeSpeciesRes) {
      let filteredSpecies;

      if (getInfo) {
        const speciesSlugToEdit = getInfo.data.species_slug;

        filteredSpecies = treeSpeciesRes.data.filter(
          (species: { species_slug: string; ages: any[] }) =>
            // Always include the species being edited based on species_slug
            species.species_slug === speciesSlugToEdit ||
            // Include other species only if they have at least one unselected age
            species.ages.some(
              (age) => !age.is_selected && age.remaining_items > 0
            )
        );
      } else {
        // In add mode
        filteredSpecies = treeSpeciesRes.data.filter(
          (species: { ages: any[] }) =>
            species.ages.some(
              (age) => !age.is_selected && age.remaining_items > 0
            )
        );
      }

      setTreeSpeciesLis(filteredSpecies);
    }
  }, [treeSpeciesRes, getInfo]);

  const speciesListArray: SpeciesList[] = treeSpeciesList.map((item) => ({
    name_transes: item.species_name_transes,
    slug: item.species_slug,
  }));

  useEffect(() => {
    if (treeSpeciesValue && treeSpeciesList.length > 0) {
      const species = treeSpeciesList.find(
        (species) => species.species_slug === treeSpeciesValue.slug
      );
      if (species) {
        setSelectedSpecies(species);
        const calculatedAges = calculateAges();
        setAges(calculatedAges);
      }
    } else {
      resetField("age");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeSpeciesValue, treeSpeciesList]);

  useEffect(() => {
    if (dateSelected && selectedSpecies) {
      const calculatedAges = calculateAges();
      setAges(calculatedAges);
      if (getInfo?.data?.supply_slug) {
        const selectedAge = calculatedAges.find(
          (age) => age.slug === getInfo.data.supply_slug
        );
        if (selectedAge) {
          setValue("age", {
            slug: getInfo.data?.supply_slug,
            label: [
              getInfo.data?.age_year > 0
                ? `${getInfo.data?.age_year} ${t("Year")}`
                : "",
              getInfo.data?.age_month > 0
                ? `${getInfo.data?.age_month} ${t("Month")}`
                : "",
            ]
              .filter(Boolean)
              .join(", "),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpecies, dateSelected]);

  useEffect(() => {
    let remainingItems = 0;

    if (selectedSpecies && ageSelected) {
      const selectedAge = selectedSpecies.ages.find(
        (age: { slug: any }) => age.slug === ageSelected.slug
      );

      if (selectedAge) {
        if (getInfo) {
          remainingItems =
            Number(selectedAge.remaining_items) +
            Number(getInfo.data?.distributed_trees);
        } else {
          remainingItems = Number(selectedAge.remaining_items) || 0;
        }
        const distributed = Number(distributedTreeSelected) || 0;

        if (!isNaN(remainingItems) && !isNaN(distributed)) {
          setRemainingTree(Math.max(0, remainingItems - distributed));
        } else {
          setRemainingTree(remainingItems);
        }
      } else {
        setRemainingTree(0);
      }
    } else {
      setRemainingTree(0);
    }
  }, [selectedSpecies, ageSelected, distributedTreeSelected, getInfo]);

  useEffect(() => {
    if (selectedSpecies && ageSelected) {
      if (getInfo) {
        setRemainingTreeSpecies(
          Number(
            selectedSpecies.ages.find(
              (age: { slug: any }) => age.slug === ageSelected.slug
            )?.remaining_items ?? 0
          ) + Number(getInfo.data?.distributed_trees)
        );
      } else {
        setRemainingTreeSpecies(
          Number(
            selectedSpecies.ages.find(
              (age: { slug: any }) => age.slug === ageSelected.slug
            )?.remaining_items ?? 0
          )
        );
      }
    }
  }, [selectedSpecies, ageSelected, getInfo]);

  useEffect(() => {
    if (getInfo) {
      setTranslatableInput({
        note: getInfo.data.note_transes,
      });
      const dateF: string[] = getInfo.data.date.split("/");
      setValue(
        "date",
        new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
      );
      setValue("order_id", getInfo.data?.order_id);
      setValue("distributed_trees", getInfo.data?.distributed_trees);
      setValue("remaining_tree", remainingTree);
      setValue("treeSpecies", {
        slug: getInfo.data.species_slug,
        name_transes: getInfo.data?.species_name_transes,
      });
      setValue("age", {
        slug: getInfo.data?.supply_slug,
        label: [
          getInfo.data?.age_year > 0
            ? `${getInfo.data?.age_year} ${t("Year")}`
            : "",
          getInfo.data?.age_month > 0
            ? `${getInfo.data?.age_month} ${t("Month")}`
            : "",
        ]
          .filter(Boolean)
          .join(", "),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    getTreeSpecies({});
    if (sendResponse) {
      const successMessage = t("Message.Successful add");

      if (action === "add more") {
        setIsShowModal(true);
        toast.success(successMessage);
      } else if (action === "add") {
        toast.success(successMessage);
        history.replace(`/setting/nurseries/inventory-tracking/${id}`);
      } else {
        const message = getInfo ? t("Message.Success update") : successMessage;
        history.replace(`/setting/nurseries/inventory-tracking/${id}`);
        toast.success(message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };
  const closeModal = () => {
    setIsShowModal(false);
    setAges([]);
    setRemainingTree(0);
    resetField("remaining_tree");
    resetField("distributed_trees");
    resetField("treeSpecies");
    resetField("age");
    setTranslatableInput({
      note: { en: "", fr: "" },
    });
    setAction("");
  };
  const NoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          note: {
            en: event.target.value,
            fr: prevState.note.fr,
          },
        };
      });
    } else if (lang === "FR") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          note: {
            en: prevState.note.en,
            fr: event.target.value,
          },
        };
      });
    }
  };

  const supplySlug =
    selectedSpecies && ageSelected
      ? selectedSpecies.ages.find(
          (age: { slug: any }) => age.slug === ageSelected.slug
        )?.slug
      : "";

  const calculateAges = (): { slug: string; label: string; date: any }[] => {
    if (dateSelected && selectedSpecies) {
      const selectedDateObj = moment(
        dateSelected,
        "ddd MMM DD YYYY HH:mm:ss Z"
      );

      const filteredAges = selectedSpecies.ages.filter(
        (ageItem: { is_selected: boolean; species_slug: string }) =>
          // In edit mode, show all ages for the species being edited
          selectedSpecies.species_slug === getInfo?.data.species_slug ||
          // Otherwise, filter to show only ages that are not selected
          !ageItem.is_selected
      );
      return filteredAges.map(
        (ageItem: {
          slug: string;
          date: any;
          age_year: any;
          age_month: any;
        }) => {
          const ageDateObj = moment(ageItem.date, "DD/MM/YYYY");

          const yearsDelta = selectedDateObj.diff(ageDateObj, "years");
          ageDateObj.add(yearsDelta, "years");

          const monthsDelta = selectedDateObj.diff(ageDateObj, "months");

          let finalYears = yearsDelta + (ageItem.age_year ?? 0);
          let finalMonths = monthsDelta + (ageItem.age_month ?? 0);

          // Adjust years and months if total months exceed 12
          if (finalMonths >= 12) {
            finalYears += Math.floor(finalMonths / 12);
            finalMonths = finalMonths % 12;
          }
          const label = [
            finalYears > 0 ? `${finalYears} ${t("Year")}` : "",
            finalMonths > 0 ? `${finalMonths} ${t("Month")}` : "",
          ]
            .filter(Boolean)
            .join(", ");
          return { slug: ageItem.slug, label, date: ageItem.date };
        }
      );
    } else {
      return [];
    }
  };

  const DistributeTreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("distributed_trees");
    }
    const value = parseInt(event.target.value, 10);
    setValue("distributed_trees", value);

    if (selectedSpecies && ageSelected) {
      const selectedAge = selectedSpecies.ages.find(
        (age: { slug: any }) => age.slug === ageSelected.slug
      );
      if (selectedAge) {
        const remainingItems = selectedAge.remaining_items ?? 0;
        setRemainingTree(Math.max(0, remainingItems - value));
      }
    }
  };

  const addDistributeTree = (data: FormValues) => {
    if (
      translatableInput.note.fr.trim() !== "" &&
      translatableInput.note.en.trim() === ""
    ) {
      toast.error(
        `${t(
          "Message.While other languages have a translation, the default language cannot be empty."
        )}`
      );
    } else if (
      ((ageSelected && ageSelected.label === "") ||
        supplySlug === undefined ||
        ageSelected === undefined) &&
      action !== ""
    ) {
      setError("age", {
        type: "custom",
        message: `${t("Message.Required field")}`,
      });
      return;
    } else if (getInfo) {
      isDirty(false);
      send({
        note_transes: translatableInput.note,
        date: `${data.date.getDate()}/${
          data.date.getMonth() + 1
        }/${data.date.getFullYear()}`,
        supply_slug: supplySlug,
        order_id: data.order_id,
        distributed_trees: data.distributed_trees,
      });
    } else {
      setDistributedTreeSelectedState(distributedTreeSelected);
      send({
        note_transes: translatableInput.note,
        date: `${data.date.getDate()}/${
          data.date.getMonth() + 1
        }/${data.date.getFullYear()}`,
        supply_slug: supplySlug,
        order_id: sendResponse ? sendResponse?.data.order_id : null,
        distributed_trees: distributedTreeSelected,
      });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(addDistributeTree)}>
        <ThemeProvider theme={theme}>
          <p className="text-sm text-default-text mb-[6px]">
            {t("Tree inventory.Language")}
          </p>
          <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
          <div className="flex flex-wrap justify-between mt-0 sm:mt-7">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Tree inventory.Date")} *
              </p>
              {getInfo || sendResponse ? (
                <input
                  type="text"
                  value={
                    getInfo
                      ? getInfo.data.date
                      : sendResponse && sendResponse?.data?.date
                  }
                  readOnly
                  className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-3"
                />
              ) : (
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="date"
                  label={t("Tree inventory.Select date")}
                  readonly={sendResponse ? true : false}
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                />
              )}

              {errors.date && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
            <div className="w-full sm:w-1/2 sm:pl-2">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Tree inventory.Order ID")}
              </p>
              <input
                type="text"
                value={
                  sendResponse
                    ? sendResponse?.data.order_id
                    : getInfo
                    ? getInfo?.data?.order_id
                    : `${nurseryCode}-X`
                }
                readOnly
                className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-3"
              />
            </div>
          </div>
          <p className="hr-lines">{t("Tree inventory.Tree distribution")}</p>
          <div className="flex flex-wrap justify-between mt-0 sm:mt-7">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p
                className={`${
                  dateSelected === undefined
                    ? "text-ph-light-gray"
                    : "text-default-text"
                } text-sm mb-[6px] mt-7 sm:mt-0`}
              >
                {t("Navbar.Tree species")} *
              </p>
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                  validate: (value: any) => {
                    if (!value || value.slug === "") {
                      return `${t("Message.Required field")}`;
                    }
                    return true;
                  },
                }}
                control={control}
                name="treeSpecies"
                selectOptions={speciesListArray}
                onCustomChange={() => {
                  setValue("distributed_trees", 0);
                }}
                placeholder={t("Navbar.Tree species")}
                getOptionLabel={(option: SpeciesList) =>
                  i18n.language === "fr"
                    ? option.name_transes?.fr === ""
                      ? option.name_transes?.en
                      : option.name_transes?.fr
                    : option.name_transes?.en
                }
                disabled={dateSelected === undefined}
              />
              {errors.treeSpecies && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
            <div className="w-full sm:w-1/2 sm:pl-2">
              <p
                className={`${
                  dateSelected === undefined
                    ? "text-ph-light-gray"
                    : "text-default-text"
                } text-sm  mb-[6px] mt-7 sm:mt-0`}
              >
                {t("Register.Age")} *
              </p>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink={false}>
                  {(ageSelected && ageSelected.label === "") ||
                  ageSelected === undefined ||
                  supplySlug === undefined
                    ? t("Register.Age")
                    : ""}
                </InputLabel>
                <Controller
                  disabled={treeSpeciesValue === undefined}
                  name="age"
                  defaultValue={ageSelected}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={field.value?.slug || field.value || ""}
                      onChange={(event) => {
                        // Update the selected age in the form state
                        field.onChange(event);

                        // Find the selected age object based on the slug
                        const selectedAge = ages.find(
                          (age) => age.slug === event.target.value
                        );

                        // If a valid age is selected, update the formatted age state
                        if (selectedAge) {
                          const [day, month, year] = selectedAge?.date
                            .split("/")
                            .map(Number);
                          const selectedAgeDate = new Date(
                            year,
                            month - 1,
                            day
                          );
                          if (
                            dateSelected &&
                            new Date(
                              dateSelected.getFullYear(),
                              dateSelected.getMonth(),
                              dateSelected.getDate()
                            ) < selectedAgeDate
                          ) {
                            toast.error(
                              t(
                                "Message.The distribution date can't be earlier than the tree supply date."
                              )
                            );
                            setFormattedAge("");
                            field.onChange({ slug: "", label: "" });
                          } else {
                            setFormattedAge(selectedAge.label);
                            field.onChange({
                              slug: selectedAge.slug,
                              label: selectedAge.label,
                            });
                            clearErrors("age");
                          }
                        } else {
                          // If no valid age is selected, reset the formatted age
                          setFormattedAge("");
                          field.onChange({ slug: "", label: "" });
                        }
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                      style={{ height: "48px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      disabled={treeSpeciesValue === undefined}
                    >
                      {ages &&
                        ages.map((age, index) => (
                          <MenuItem key={index} value={age.slug}>
                            {age.label}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
              {((ageSelected && ageSelected.label === "") ||
                supplySlug === undefined ||
                ageSelected === undefined) &&
                action !== "" && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
            </div>
          </div>
          <div className="flex flex-wrap justify-between mt-0 sm:mt-7">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Tree inventory.Distributed trees")} *
              </p>
              <NumberField
                onChange={DistributeTreeChange}
                clearError={clearErrors}
                getValues={getValues}
                setValue={setValue}
                maxValue={remainingTreeSpecies}
                inputStyle="py-3 
                    placeholder-ph-light-gray"
                name="distributed_trees"
                placeholder={t("Tree inventory.Distributed trees") + ""}
                register={register("distributed_trees", {
                  required: `${t("Message.Required field")}`,
                  pattern: /^[+]?\d+/,
                  min: {
                    value: 1,
                    message: `${t("Message.The minimum number is 1")}`,
                  },
                  max: {
                    value: remainingTreeSpecies,
                    message: `${t(
                      "Message.The distributed trees is more than remaining trees"
                    )}`,
                  },
                })}
                styleClass="w-full"
                readOnl={false}
                disabled={ageSelected === undefined}
              />
              {errors.distributed_trees?.type === "pattern" && (
                <p className="error-text">{t("Message.Invalid number")}</p>
              )}
              {errors.distributed_trees?.type === "required" && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
              {errors.distributed_trees?.type === "min" && (
                <p className="error-text">
                  {t("Message.The minimum number is 1")}
                </p>
              )}
              {errors.distributed_trees?.type === "max" && (
                <p className="error-text">
                  {t(
                    "Message.The distributed trees is more than remaining trees"
                  )}
                </p>
              )}
            </div>
            <div className="w-full sm:w-1/2 sm:pl-2">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Tree inventory.Remaining trees")}
              </p>
              <input
                type="text"
                value={remainingTree}
                readOnly
                className="text-sm bg-ph-bg-gray text-white border border-ph-iborder outline-none rounded-md w-full pl-4 py-3"
              />
            </div>
          </div>
          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Tree inventory.Note")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={NoteChange}
            value={
              lang === "EN"
                ? translatableInput.note.en
                : translatableInput.note.fr
            }
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree inventory.Note") + ""}
          />
        </ThemeProvider>
        <AddDistributeButton
          update={getInfo}
          setAction={setAction}
          isDirty={isDirty}
        />
      </form>
      {sendResponse && (action === "add" || action === "add more") && (
        <AddDistributeSuccess
          isShow={isShowModal}
          onOK={() => {
            closeModal();
            return true;
          }}
          onClose={closeModal}
          distributedTreeSelected={distributedTreeSelectedState}
          selectedSpecies={
            selectedSpecies
              ? i18n.language === "fr"
                ? selectedSpecies.species_name_transes?.fr === ""
                  ? selectedSpecies.species_name_transes?.en
                  : selectedSpecies.species_name_transes?.fr
                : selectedSpecies.species_name_transes?.en
              : ""
          }
          ageSelected={formattedAge}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default DistributeTreeForm;
