import React, { useEffect, useState } from "react";
import { CommunityRecord } from "../../../type/communityRecord";
import { t } from "i18next";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faBriefcase,
  faBuilding,
  faHandHoldingSeedling,
  faLandmark,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { Incidents } from "../../../type/incidents";
import { useHistory } from "react-router-dom";

const CommunityTab: React.FC<{
  response: CommunityRecord;
}> = ({ response }) => {
  const active =
    i18n.language === "en"
      ? response.is_active_transes.en
      : response.is_active_transes.fr;

  const history = useHistory();
  const [incidents, setIncidents] = useState<Incidents[]>([]);

  useEffect(() => {
    if (response && response.incidents) {
      setIncidents(response.incidents);
    } else {
      setIncidents([]);
    }
  }, [response]);
  return (
    <div className="flex flex-col p-6 md:h-[807px] bg-ph-white">
      <div className="flex justify-start flex-col border-[1px] border-ph-input border-opacity-100 md:h-[186px] p-4">
        <div className="flex flex-row justify-start  mb-4">
          <div className="flex items-center justify-center w-[56px] h-[56px] rounded-[5px] bg-ph-light-blue mr-2">
            <p className="text-ph-dark-blue text-[26px] font-semibold h-[36px] mt-[13px] mb-[12px] mx-[10px] w-[36px] flex items-center justify-center">
              {response.initials[0] + response.initials[1]}
            </p>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-col items-start text-center mr-[50px] md:mr-[219px]">
              <p className="text-sm text-ph-gray">{t("Community.Initials")}</p>
              <p className="text-sm text-default-text font-medium">
                {response.initials}
              </p>
            </div>
            <div className="ml-4">
              <p
                className={`${
                  response.is_active_transes.en === "Active"
                    ? "bg-ph-toggle-bg-green text-ph-toggle-text-green px-[15px]"
                    : "bg-ph-toggle-bg-red text-ph-red-warning"
                } text-[12px] text-center font-medium w-[68px] h-[31px] rounded-[14px] py-2`}
              >
                {active}
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex items-center mt-6">
          <div className="flex flex-col md:flex-row md:items-center">
            <span className="flex flex-row mb-5 md:mb-0">
              <FontAwesomeIcon
                className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                icon={faUsers}
              />
              <div className="mr-[205px]">
                <p className="text-sm text-ph-gray">
                  {t("Community.Community")}
                </p>
                <p className="text-sm text-default-text font-medium">
                  {response.name}
                </p>
              </div>
            </span>
            <span className="flex flex-row mb-5 md:mb-0">
              <FontAwesomeIcon
                className="text-ph-gray w-[15px] h-[20px] my-[11px] mr-[26px]"
                icon={faBuilding}
              />
              <div className="mr-[206px]">
                <p className="text-sm text-ph-gray ">
                  {t("PlantingFilter.Province")}
                </p>
                <p className="text-sm text-default-text font-medium">
                  {response.department}
                </p>
              </div>
            </span>
            <span className="flex flex-row">
              <FontAwesomeIcon
                className="text-ph-gray w-[20px] h-[20px] my-[11px] mr-[16px]"
                icon={faLandmark}
              />
              <div>
                <p className="text-sm text-ph-gray">
                  {t("Plantings.District")}
                </p>
                <p className="text-sm text-default-text font-medium">
                  {response.municipality}
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col border-[1px] border-ph-input border-opacity-100 md:h-[478px] p-4 mt-4">
        <p className="text-sm text-default-text font-semibold">
          {t("Statistics.Statistics")}
        </p>
        <div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center h-[49px] bg-ph-bg-white p-4 mt-[24px]">
              <FontAwesomeIcon
                className="text-ph-gray w-[22.4px] h-[20px] my-[11px] mr-[16px]"
                icon={faHandHoldingSeedling}
              />
              <p className="text-sm text-ph-dark-gray font-medium">
                {t("Events.Events")}
              </p>
            </div>
            <div className="flex flex-col md:flex-row mt-[12px] px-3">
              <div className="mr-5 md:mr-[240px] h-[42px] mb-5 md:mb-0">
                <p className="text-ph-gray text-sm font-normal">
                  {t("Events.Events")}
                </p>
                <p className="text-sm text-default-text font-medium mt-[6px]">
                  {response.planting_no}
                </p>
              </div>
              <div className="mr-5 w-[107px] h-[42px] mb-5 md:mb-0">
                <p className="text-ph-gray text-sm font-normal">
                  {t("PlantingFilter.Participants")}
                </p>
                <p className="text-sm text-default-text font-medium mt-[6px]">
                  {response.planting_participants_no}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-[12px]">
            <div className="flex flex-row items-center h-[49px] bg-ph-bg-white p-4 mt-[24px]">
              <FontAwesomeIcon
                className="text-ph-gray w-[21.3px] h-[20px] my-[11px] mr-[16px]"
                icon={faBriefcase}
              />
              <p className="text-sm text-ph-dark-gray font-medium">
                {t("Workshops.Workshops")}
              </p>
            </div>
            <div className="flex flex-col md:flex-row mt-[12px] px-3">
              <div className="mr-5 md:mr-[240px] w-[107px] h-[42px] mb-5 md:mb-0">
                <p className="text-ph-gray text-sm font-normal">
                  {t("Workshops.Workshops")}
                </p>
                <p className="text-sm text-default-text font-medium mt-[6px]">
                  {response.workshops_no}
                </p>
              </div>
              <div className="mr-[246px] w-[107px] h-[42px]">
                <p className="text-ph-gray text-sm font-normal">
                  {t("PlantingFilter.Participants")}
                </p>
                <p className="text-sm text-default-text font-medium mt-[6px]">
                  {response.workshop_participants_no}
                </p>
              </div>
            </div>
          </div>
          {incidents.length > 0 && (
            <div className="flex flex-col mt-[12px]">
              <div className="flex flex-row items-center h-[49px] bg-ph-bg-white p-4 mt-[24px]">
                <FontAwesomeIcon
                  className="text-ph-gray w-[15px] h-[20px] my-[11px] mr-[16px]"
                  icon={faBolt}
                />
                <p className="text-sm text-ph-dark-gray font-medium">
                  {t("Incidents.Incidents")}
                </p>
              </div>
              <div className="flex flex-col md:flex-row mt-[12px] px-3">
                {incidents.map((incident) => {
                  let incidentName;
                  if (i18n.language === "fr") {
                    incidentName =
                      incident.name_transes.fr === ""
                        ? incident.name_transes.en
                        : incident.name_transes.fr;
                  } else {
                    incidentName = incident.name_transes.en;
                  }
                  const isLongName = incidentName.length > 17;
                  return (
                    <div
                      key={incident.id}
                      className={`${
                        isLongName
                          ? "w-[200px] mr-[150px]"
                          : "w-[107px] mr-[240px]"
                      } h-[42px]`}
                    >
                      <p
                        key={incident.id}
                        className="text-ph-gray text-sm font-normal"
                      >
                        {incidentName}
                      </p>
                      <p className="text-sm text-default-text font-medium mt-[6px]">
                        {incident.count}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div>
            <button
              type="button"
              onClick={() => history.goBack()}
              className="main-btn text-sm w-[200px] py-2 mt-5 font-medium float-right mr-4"
            >
              {t("Community.Back to communities")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityTab;
